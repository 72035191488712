import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts';
import './assets/css/custom.css'
import './assets/css/el.css'
import 'font-awesome/css/font-awesome.css';
import jq from "jquery";
import JSEncrypt from 'jsencrypt';
import commonParam from "@/param/commonParam";

Vue.use(ElementUI, { size: 'mini' });
Vue.prototype.$echarts = echarts;
// 是否是开发模式
Vue.config.productionTip = false;

Vue.prototype.$getRsaCode = async function (str) { // 注册方法
  let data = '';
  jq.ajax({
    async: false,
    type: "get",
    url: commonParam.serverUrl + commonParam.getPublicKeyUrl,
    success: function (res) {
      if (res) {
        let pubKey = res;
        let encryptStr = new JSEncrypt();
        encryptStr.setPublicKey(pubKey); // 设置 加密公钥
        data = encryptStr.encrypt(str.toString());  // 进行加密
      }
    }
  });
  return data;
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
