const bizPages = [
    // 页面配置

    {
        //商品管理
        path: '/bizCommodityControl',
        name: 'BizCommodityControl',
        component: () => import("@/views/biz/BizCommodityControl")
    },
    {
        //商品审核
        path: '/itemAudit',
        name: 'ItemAudit',
        component: () => import("@/views/biz/BizCommodityControl/auditForm")
    },
    {
        //企业审核
        path: '/bizEnterpriseExamine',
        name: 'BizEnterpriseExamine',
        component: () => import("@/views/biz/BizEnterpriseExamine")
    },
    {
        //分类管理
        path: '/itemCategory',
        name: 'ItemCategory',
        component: () => import("@/views/biz/ItemCategory")
    },
    {
        //Sku管理
        path: '/bizSkuControl',
        name: 'bizSkuControl',
        component: () => import("@/views/biz/BizSkuControl")
    },{
        //商品供应
        path: '/itemSupply',
        name: 'ItemSupply',
        component: () => import("@/views/biz/ItemSupply")
    },
    {
        //商品需求
        path: '/itemDemand',
        name: 'ItemDemand',
        component: () => import("@/views/biz/ItemDemand")
    },
    {
        //商品供应审核
        path: '/itemSupplyAudit',
        name: 'ItemSupplyAudit',
        component: () => import("@/views/biz/ItemSupplyAudit")
    },
    {
        //商品需求审核
        path: '/itemDemandAudit',
        name: 'ItemDemandAudit',
        component: () => import("@/views/biz/ItemDemandAudit")
    },
    {
        //活动专区
        path: '/activityAreas',
        name: 'ActivityAreas',
        component: () => import("@/views/biz/ActivityAreas")
    },
    {
        //店铺信息
        path: '/shopInfo',
        name: 'ShopInfo',
        component: () => import("@/views/biz/ShopInfo")
    },
    {
        //商品品牌
        path: '/itemBrand',
        name: 'ItemBrand',
        component: () => import("@/views/biz/ItemBrand")
    },
    {
        //商品图片
        path: '/itemImage',
        name: 'ItemImage',
        component: () => import("@/views/biz/ItemImage")
    },
    {
        //商品专题（专区）
        path: '/itemSubject',
        name: 'ItemSubject',
        component: () => import("@/views/biz/ItemSubject")
    },
    {
        //活动商品管理（专区）
        path: '/itemSubjectRelation',
        name: 'ItemSubjectRelation',
        component: () => import("@/views/biz/ItemSubjectRelation")
    },
    {
        //活动白名单（专区）
        path: '/itemSubjectUser',
        name: 'ItemSubjectUser',
        component: () => import("@/views/biz/ItemSubjectUser")
    },
    {
        //店铺信息
        path: '/shopInfo',
        name: 'ShopInfo',
        component: () => import("@/views/biz/ShopInfo")
    },
    {
        //店铺审核
        path: '/shopInfoCheck',
        name: 'ShopInfoCheck',
        component: () => import("@/views/biz/ShopInfoCheck")
    },
    {
        //我的店铺信息
        path: '/myShopInfo',
        name: 'MyShopInfo',
        component: () => import("@/views/biz/MyShopInfo")
    },
    {
        // 规格组管理
        path: '/itemSpecsGroup',
        name: 'ItemSpecsGroup',
        component: () => import("@/views/biz/ItemSpecsGroup")
    },
    {
        // 规格参数管理
        path: '/itemSpecsParam',
        name: 'ItemSpecsParam',
        component: () => import("@/views/biz/ItemSpecsParam")
    },
    {
        // 订单
        path: '/itemOrder',
        name: 'ItemOrder',
        component: () => import("@/views/biz/ItemOrder")
    },
    {
        // 店员管理
        path: '/shopUser',
        name: 'ShopUser',
        component: () => import("@/views/biz/ShopUser")
    },
];

export default bizPages;
