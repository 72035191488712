// 系统页面
const SystemPages = [
    {
        path: "/main",
        name: "Main",
        meta: { fatherMenuName: '', menuName: '首页', isLogin: true },
        component: () => import("@/views/home/main")
    },
    {
        path: "/bizSysDict",
        name: "BizSysDict",
        meta: { fatherMenuName: '', menuName: '字典', isLogin: true },
        component: () => import("@/views/system/SysDict")
    }
];

export default SystemPages;
