const commonParam = {
  // 服务器路径
  serverUrl: 'https://api2.tfyxh.com/',
  // serverUrl: 'http://127.0.0.1:8088/',
  // serverUrl: 'http://10.9.17.82:83/',
  // serverUrl: 'http://192.168.1.103:23182',
  //密码加密公钥
  getPublicKeyUrl: "uaa/public-key",
  // 文件下载路径
  downloadUrl: '/download',
  // 系统名称
  systemName: "njkfgl",
  // 图片地址接口地址
  showImgUrl: 'https://eps.smesc.cn/img',
  // showImgUrl: 'http://192.168.1.212/smesc',
  // showImgUrl: 'http://10.9.17.82:84/smesc',
  // 操作方式
  handleType: {
    addType: 'ADD',
    updateType: 'UPDATE',
    lookType: 'LOOK'
  },
  // token过期时间(毫秒)比服务器值减少1分钟
  tokenOverdueTime: 7140 * 1000,
  // 刷新token过期时间(毫秒)比服务器值减少1分钟`
  refreshTokenOverdueTime: 259140 * 1000,
  // 页眉名字
  headerName: "天府云管理系统",
  // 首页左上角名字
  tabName: '天府云管理系统',
  // 登录页面名字
  loginPlatformName: '天府云管理系统',
  // 系统脚下名字
  footerText: '系统版本v1.0&nbsp;&copy;&nbsp;2022&nbsp;<i class="fa fa-fw fa-phone"></i>028-00000000',
  // 限制接口权限页面展示,目前国土项目不需要接口细粒度控制，
  flgBtn: false,
};

export default commonParam;
