import Vue from "vue";
import VueRouter from "vue-router";
import system from "./system";
import business from "./business";
import auth from "./auth";

Vue.use(VueRouter);

const Login = () => import("@/views/Login")
const Home = () => import("@/views/home"); //后台首页

const routes = [
  { path: '/', redirect: "/login" },
  { path: '/login', name: 'Login', component: Login },
  {
    path: "/home", name: "Home", redirect: '/main', component: Home,
    children: [
        ...system,...business, ...auth
    ]
  },
  {
    path: '/error',
    component: () => import('@/views/ErrorPage.vue'),
    meta: { title: 'Error', requiresAuth: true }
  },
  { path: '*', redirect: '/404' },
  {
    path: '/404',
    name: '404',
    meta: { title: '404', requiresAuth: true },
    component: () => import('../views/404.vue')
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

export default router;
