// 系统页面
const AuthPage = [
    {
        path: "/authUser",
        name: "AuthUser",
        meta: { fatherMenuName: '', menuName: '用户', isLogin: true },
        component: () => import("@/views/auth/admin/AuthUser")
    },
    {
        path: "/authMenu",
        name: "AuthMenu",
        meta: { fatherMenuName: '', menuName: '菜单', isLogin: true },
        component: () => import("@/views/auth/admin/AuthMenu")
    },
    {
        path: "/authMenuRole",
        name: "AuthMenuRole",
        meta: { fatherMenuName: '', menuName: '菜单角色', isLogin: true },
        component: () => import("@/views/auth/admin/AuthMenuRole")
    },
    {
        path: "/authRole",
        name: "AuthRole",
        meta: { fatherMenuName: '', menuName: '权限角色', isLogin: true },
        component: () => import("@/views/auth/admin/AuthRole")
    },
    {
        path: "/authPermission",
        name: "AuthPermission",
        meta: { fatherMenuName: '', menuName: '权限', isLogin: true },
        component: () => import("@/views/auth/admin/AuthPermission")
    },
    {
        path: "/authUserCheck",
        name: "AuthUserCheck",
        meta: { fatherMenuName: '', menuName: '用户审核', isLogin: true },
        component: () => import("@/views/auth/admin/AuthUserCheck")
    }
];

export default AuthPage;
